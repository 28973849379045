export default async function (selector) {
  const images = document.querySelectorAll(selector);
  
  async function convert (image) {
    try {
      const res = await fetch(image.src);
      const data = await res.text();
  
      const parser = new DOMParser();
      const svg = parser.parseFromString(data, 'image/svg+xml').querySelector('svg');
  
      if (image.id) svg.id = image.id;
      if (image.className) svg.classList = image.classList;
  
      image.parentNode.replaceChild(svg, image);
    } catch (err) {
      console.error({
        message: "SVG convert error",
        item: image,
        err
      })
    }
  }
  
  const promises = Array.from(images).map(convert);
  await Promise.all(promises);
}