export default class {
  constructor({input}) {
    const wrapper = typeof input === "string" ? document.querySelector(input) : input;
    this.elements = {
      wrapper,
      fileInput: wrapper.querySelector(".file-input__file-input"),
      filesWrapper: wrapper.querySelector(".file-input__files-wrapper")
    }
    
    this.elements.fileInput.addEventListener("change", this.fileOnChange.bind(this));
  }
  
  fileOnChange() {
    this.files = [...this.elements.fileInput.files].reduce((result, file) => result.concat([file.name]), []);
  }
  
  set files(files) {
    this.elements.filesWrapper.innerHTML = files.reduce((result, fileName) => {
      return result + this.fileNameTemplate(fileName);
    }, "");
  }
  
  fileNameTemplate = name => `<span class="file-input__file">${name}</span>`;
}