import convertSVG from "./js/convertSVG";
import {Sticky} from "stuck-js";
import Form from "./js/Form"
import 'element-closest-polyfill';
import "@splidejs/splide/dist/css/splide.min.css"
import "./scss/main.scss";

//Convert img tag to inline svg
convertSVG("img.svg");

//Instagram gradient set
const instagramGradient = `
  <svg>
    <defs>
      <radialGradient id="instagramGradient" cx="20%" cy="90%" r="107%">
        <stop offset="0%" stop-color="#fdf497"/>
        <stop offset="5%" stop-color="#fdf497"/>
        <stop offset="45%" stop-color="#fd5949"/>
        <stop offset="60%" stop-color="#d6249f"/>
        <stop offset="90%" stop-color="#285AEB"/>
      </radialGradient>
    </defs>
  </svg>
`
const gradientsContainer = document.createElement("div");
gradientsContainer.setAttribute("style", "height: 0; width: 0; position: absolute; left: -200vw;")
gradientsContainer.innerHTML = instagramGradient;
document.body.appendChild(gradientsContainer);

//Sticky header
new Sticky(document.querySelector('#top-line'), { marginTop: 0, wrapper: document.body });

//Forms
const forms = document.querySelectorAll(".form");
[...forms].forEach(async (form) => {
  const formElement = new Form({
    form,
    async afterSubmit () {
      const Popup = (await import("./js/Popup")).default;
      const thanksPopup = new Popup({
        link: "/popups/thanks.html"
      });
      await thanksPopup.open();
    },
    required: ["phone"]
  });
  await formElement.initInputs();
});

//Script after full content loaded
document.addEventListener("DOMContentLoaded", () => {
  setTimeout(async () => {
    const afterDOMLoadedScript = await import("./afterDOMLoaded");
    afterDOMLoadedScript.init();
  }, 1000);
});