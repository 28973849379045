import IMask from 'imask';
import customSelect from "custom-select";
import FileInput from "./FileInput";
import asyncDelay from "./utils/asyncDelay";

export default class {
  afterSubmit;
  errorWrapper;
  
  constructor ({form, afterSubmit = false, required = false}) {
    this.form = typeof form === "string" ? document.querySelector(form) : form;
    this.errorWrapper = this.form.querySelector(".form-error");
    
    this.form.addEventListener("submit", this.onSubmit.bind(this));
    this.afterSubmit = afterSubmit;
    this.required = required;
  }
  
  async onSubmit (event) {
    event.preventDefault();
  
    if (!this.required || !this.verify()) return false;
    
    let response;
    try {
      response = await fetch('/api/send.php', {
        method: 'POST',
        body: this.formData
      });
    } catch (err) {
      console.log(err);
    }
  
    if (response.status === 200) {
      this.clear();
      if (this.afterSubmit) this.afterSubmit.bind(this)();
    }
    
  }
  
  get formData () {
    return new FormData(this.form);
  }
  
  
  async initInputs () {
  
    //Phone mask
    IMask(this.form.querySelector(".input_phone"),{
      mask: '+{375} (00) 000-00-00'
    })
    
    //Select
    const selects = this.form.querySelectorAll(".select");
    if (selects.length) {
      [...selects].forEach(select => customSelect(select, {
        containerClass: 'select',
        panelClass: 'select__panel',
        openerClass: 'select__opener',
        optionClass: 'select__option',
        hasFocusClass: 'option-focus'
      }));
    }
    
    //File input
    const fileInput = this.form.querySelector(".file-input");
    if (fileInput) {
      new FileInput({
        input: fileInput
      });
    }
    
    [...this.form.querySelectorAll("input, select, textarea")].forEach(input => input.addEventListener("change", () => {
      if (input.tagName === "SELECT") {
        input.closest(".customSelect").classList.add("selected");
      }
      
      input.closest(".input, .customSelect").classList.remove("input-error");
    }));
    
  }
  
  async showError(errors) {
    if (errors) {
      this.errorWrapper.innerHTML = errors > 1 ? "Заполните выделенные поля" : "Заполните выделенное поле";
      this.errorWrapper.style.display = "block";
      await asyncDelay(100);
      this.errorWrapper.classList.add("error-active");
    } else {
      this.errorWrapper.classList.remove("error-active");
      await asyncDelay(300);
      this.errorWrapper.style.display = "none";
      this.errorWrapper.innerHTML = "";
    }
  }
  
  verify() {
    const errors = this.required.reduce((accumulator, inputName) => {
      const input = this.form.querySelector(`*[name=${inputName}]`);
      if (!input) return accumulator;
      
      let empty;
      switch (inputName) {
        case "phone":
          empty = input.value.length < 19;
          break;
        default:
          empty = !input.value.length || input.value === "Выберите услугу";
          break;
      }
  
      if (empty) input.closest(".input, .customSelect").classList.add("input-error");
      
      return empty ? accumulator + 1 : accumulator;
    }, 0);
  
    this.showError(errors);
    
    return !errors;
  }
  
  clear () {
    [...this.form.querySelectorAll(".input")].forEach(input => input.value = "");
  }
  
}